<template>
  <div class="dust-battle">
    <CompHeader
      :backFunc="
        () => {
          $router.push('/');
        }
      "
      title="对战">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="float-bottom-box">
      <div class="float-bottom-header flex-b">
        <div
          @click="battleStatusOnClick(item)"
          v-for="item in battleStatusList"
          :key="item.value"
          class="filter-item flex-s">
          <div style="background: #332a19" class="rem-password-checkbox">
            <div
              v-show="activeBattleStatus === item.value"
              class="float-gou-icon">
              <div class="bg-img"></div>
            </div>
          </div>
          <div style="color: #fff" class="rem-password-text">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="float-bottom-btns flex-b">
        <div @click="$router.push('/tenBastBattle')" class="btn btn1 flex-c">
          最佳对战
        </div>
        <div @click="$router.push('/historyBattle')" class="btn btn2 flex-c">
          历史记录
        </div>
        <div @click="$router.push('/createBattle')" class="float-btn flex-c">
          创建对战
        </div>
      </div>
    </div>
    <div class="battle-search-box flex-b">
      <div class="battle-search-left">
        <div class="input-box">
          <el-input
            maxlength="16"
            class="clear-input-style form-item-input"
            v-model="searchKeywords"
            placeholder="请输入想要搜索的对战名称"></el-input>
        </div>
        <div @click="nameSearchOnClick" class="float-right-icon point"></div>
      </div>
      <div @click="numFilterOnClick" class="battle-search-right flex-b">
        <div class="text-box">{{ activeBoxPrice.name }}</div>
        <div class="icon-box"></div>
        <div v-if="showNumFilterList" class="search-filter-box flex-s">
          <div class="in-search-filter-box flex-s">
            <div
              @click="priceOnClick(item)"
              v-for="(item, index) in boxPriceFilterList"
              :key="index"
              class="filter-item flex-c"
              :class="{
                'filter-item-active': activeBoxPrice.value === item.value,
              }">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="battle-filter-box flex-s">
      <div class="in-battle-filter-box flex-b">
        <div
          @click="battleTypeOnClick(item)"
          v-for="(item, index) in battleTypeFilterList"
          :key="index"
          class="filter-item flex-c"
          :class="{
            'filter-item-active': activeBattleType === item.value,
          }">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="battle-main-box">
      <div class="battle-main-header flex-b">
        <div class="main-left-filter-box flex-s">
          <div
            @click="anchorTypeOnClick(item)"
            v-for="item in anchorTypeList"
            :key="item.value"
            class="filter-item flex-s">
            <div style="background: #4f4424" class="rem-password-checkbox">
              <div
                v-show="activeAnchorType === item.value"
                class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div style="color: #fff" class="rem-password-text">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div @click="isShowIntroduce = true" class="main-right-tips-box flex-s">
          <div class="icon"></div>
          <div class="text">玩法介绍</div>
        </div>
      </div>
      <div class="battle-main-list-box flex-b">
        <div
          @click="roomItemOnClick(item)"
          v-for="item in finalBattleList"
          :key="item.id"
          style="width: 48%"
          class="battle-main-list-box-item">
          <BattleItem :item="item" />
        </div>
        <Pagination
          :total="pageTotal"
          :limit="pageSize"
          @pagination="pageChangeOnClick" />
      </div>
    </div>
    <van-overlay :lock-scroll="false" :show="isShowIntroduce">
      <CompPopup @close="isShowIntroduce = false">
        <div class="overlay-introduce">
          <div class="title">玩法说明</div>
          <div class="content">对战玩法说明</div>
          <div class="btn-box flex-c">
            <div @click="isShowIntroduce = false" class="btn flex-c">关闭</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getBattleRoomListApi } from "@/network/api.js";
import BattleItem from "./components/BattleItem.vue";
export default {
  components: { BattleItem },
  name: "DustBattle",
  data() {
    return {
      isShowIntroduce: false,
      searchKeywords: "",
      showNumFilterList: false,
      boxPriceFilterList: [
        { name: "全部", value: undefined, start: 0.01, end: 99999999 },
        { name: "0-200", value: "1", start: 0.01, end: 200 },
        { name: "200-800", value: "2", start: 200, end: 800 },
        { name: "800+", value: "3", start: 800, end: 99999999 },
      ],
      activeBoxPrice: {
        name: "全部",
        value: undefined,
        start: 0,
        end: 99999999,
      },
      battleTypeFilterList: [
        { name: "全部", value: undefined },
        { name: "欧皇", value: "1" },
        { name: "非酋", value: "2" },
      ],
      activeBattleType: undefined,
      anchorTypeList: [
        { name: "全部", value: undefined },
        { name: "玩家", value: "0" },
        { name: "主播", value: "1" },
      ],
      activeAnchorType: undefined,
      battleStatusList: [
        { name: "等待中", value: "0" },
        { name: "对战中", value: "1" },
        { name: "已结束", value: "2" },
      ],
      activeBattleStatus: "0",
      battleList: [],
      pageTotal: 1,
      pageSize: 1,
      currentPage: 1,
    };
  },
  watch: {
    ChuangjianVsData(val) {
      console.log("创建房间websocket信息watch", val);
      this.getBattleRoomListFun(false);
    },
    // KaiShiVsData(val) {
    //   console.log("创建房间websocket信息watch", val);
    //   this.getBattleRoomListFun(false);
    // },
    JieShuVsData(val) {
      console.log("创建房间websocket信息watch", val);
      this.getBattleRoomListFun(false);
    },
    JiaRuVsData(val) {
      console.log("创建房间websocket信息watch", val);
      this.getBattleRoomListFun(false);
    },
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState([
      "user",
      "JiaRuVsData",
      "KaiShiVsData",
      "JieShuVsData",
      "RoundVsData",
      "ChuangjianVsData",
    ]),
    finalBattleList() {
      return (this.battleList || []).map((wrapItem) => {
        let newSeatList = new Array(wrapItem.user_num).fill(null);
        newSeatList = newSeatList.map((wItem, index) => {
          let seatItem = wItem;
          wrapItem.game_arena_player.forEach((vItem) => {
            if (vItem.seat === index) {
              seatItem = vItem;
            }
          });
          return seatItem;
        });
        return {
          ...wrapItem,
          newSeatList,
        };
      });
    },
  },
  methods: {
    init() {
      this.getBattleRoomListFun();
    },
    pageChangeOnClick(item) {
      this.currentPage = item.page;
      this.getBattleRoomListFun(false);
    },
    nameSearchOnClick() {
      this.getBattleRoomListFun();
    },
    battleStatusOnClick(item) {
      this.activeBattleStatus = item.value;
      this.getBattleRoomListFun();
    },
    anchorTypeOnClick(item) {
      this.activeAnchorType = item.value;
      this.getBattleRoomListFun();
    },
    priceOnClick(item) {
      this.activeBoxPrice = item;
      this.getBattleRoomListFun();
    },
    numFilterOnClick() {
      this.showNumFilterList = !this.showNumFilterList;
    },
    battleTypeOnClick(item) {
      this.activeBattleType = item.value;
      this.getBattleRoomListFun();
    },
    roomItemOnClick(item) {
      this.$router.push({
        path: "/battleRoom",
        query: {
          id: item.id,
        },
      });
    },
    async getBattleRoomListFun(isResetPage = true) {
      if (isResetPage) {
        this.currentPage = 1;
      }
      try {
        const params = {
          page: this.currentPage,
          status: this.activeBattleStatus,
          type: this.activeBattleType,
          anchor: this.activeAnchorType,
          name: this.searchKeywords,
          start: this.activeBoxPrice.start,
          end: this.activeBoxPrice.end,
        };
        Object.keys(params).forEach((key) => {
          if (!params[key] && params[key] !== 0) {
            delete params[key];
          }
        });
        const res = await getBattleRoomListApi(params);
        console.log("对战列表查询", res);
        if (res?.data?.code === 200) {
          this.battleList = res?.data?.data?.data;
          this.pageTotal = res?.data?.data?.total;
          this.pageSize = res?.data?.data?.per_page;
          console.log("对战列表", this.battleList);
        } else {
          this.$message.error("对战列表查询失败");
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.dust-battle {
  height: 100vh;
  padding: 1.38rem 0rem 0.9rem;
  position: relative;
  .overlay-introduce {
    width: 2.75rem;
    .title {
      font-weight: 500;
      font-size: 0.18rem;
      text-align: center;
      margin-bottom: 0.16rem;
    }
    .content {
      margin-bottom: 0.18rem;
      font-size: 0.12rem;
      color: #999999;
      max-height: 2.7rem;
      overflow-y: auto;
    }
    .btn-box {
      .btn {
        width: 1.4rem;
        height: 0.38rem;
        font-weight: 500;
        font-size: 0.14rem;
        color: #000000;
        @include bgImg("seat-not-ready-icon");
      }
    }
  }
  .comp-header-right-box {
    position: absolute;
    right: 0.2rem;
    top: 0.13rem;
  }
  .float-bottom-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.9rem;
    padding: 0.12rem 0.2rem 0.08rem;
    background: #4f4424;
    z-index: 99;
    .float-bottom-header {
      margin-bottom: 0.16rem;
    }
    .float-bottom-btns {
      position: relative;
      .btn {
        width: 0.86rem;
        height: 0.26rem;
        font-size: 0.12rem;
        // background: #33211a;
        @include bgImg("bg38803");
      }
      .float-btn {
        width: 1.4rem;
        height: 0.4rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: 0.16rem;
        color: #312f2d;
        @include bgImg("create-battle-bg");
      }
    }
  }

  .battle-search-box {
    margin-bottom: 0.12rem;
    padding: 0 0.12rem;
    position: fixed;
    left: 0;
    top: 0.58rem;
    z-index: 11;
    width: 100%;
    .battle-search-left {
      width: 2.45rem;
      height: 0.22rem;
      background: rgba(42, 27, 0, 0.6);
      position: relative;
      .input-box {
        flex: 1;
        height: 100%;
        border: 0.01rem solid #817043;
        .form-item-input {
          .el-input__inner {
            font-size: 0.12rem;
            &::placeholder {
              font-size: 0.12rem;
              color: #a4a4a4;
            }
          }
        }
      }
      .float-right-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 0.46rem;
        height: 100%;
        @include bgImg("search-icon-plus");
      }
    }
    .battle-search-right {
      width: 0.86rem;
      height: 0.22rem;
      background: rgba(42, 27, 0, 0.6);
      border: 0.01rem solid #817043;
      position: relative;
      padding: 0.05rem 0.09rem;
      position: relative;
      //   @include bgImg("blind-btn-bg");
      .search-filter-box {
        width: 100vw;
        height: 0.54rem;
        position: absolute;
        right: -0.13rem;
        top: 0.22rem;
        background: rgba(29, 19, 0, 1);
        .in-search-filter-box {
          padding: 0.12rem 0.2rem;
          .filter-item {
            width: 0.71rem;
            height: 0.3rem;
            background: #69552d;
            font-size: 0.12rem;
            color: #a89265;
            margin-right: 0.17rem;
            flex-shrink: 0;
          }
          .filter-item-active {
            background: rgba(255, 213, 129, 0.5);
            color: #fff;
          }
        }
      }
      .text-box {
        font-size: 0.12rem;
        color: #ffe194;
      }
      .icon-box {
        width: 0.03rem;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          border-left: 0.045rem solid transparent;
          border-right: 0.045rem solid transparent;
          border-top: 0.06rem solid #ffe194; /* 箭头的颜色 */
          right: 0rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .battle-filter-box {
    margin-bottom: 0.12rem;
    padding: 0 0.2rem;
    position: fixed;
    left: 0;
    top: 0.88rem;
    z-index: 10;
    width: 100%;
    .in-battle-filter-box {
      width: 100%;
      padding: 0.04rem;
      background: rgba(22, 21, 20, 1);
      .filter-item {
        width: 0.96rem;
        height: 0.3rem;
        // background: #69552d;
        @include bgImg("record-table");
        font-size: 0.12rem;
        color: #a89265;
        margin-right: 0.17rem;
        flex-shrink: 0;
      }
      .filter-item-active {
        @include bgImg("record-actable");
        // background: rgba(255, 213, 129, 0.5);
        color: #fff;
      }
    }
  }
  .battle-main-box {
    padding: 0.09rem 0.2rem;
    height: calc(100vh - 2.28rem);
    overflow-y: auto;
    .battle-main-header {
      margin-bottom: 0.08rem;
      .main-left-filter-box {
        flex: 1;
        .filter-item {
          margin-right: 0.17rem;
        }
      }
      .main-right-tips-box {
        .icon {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.08rem;
          @include bgImg("tips-icon");
        }
        .text {
          font-size: 0.12rem;
          color: #ffedd1;
        }
      }
    }
    .battle-main-list-box {
      flex-wrap: wrap;
    }
  }
}
</style>
